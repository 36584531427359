<template>
  <b-card-code
    no-body
    title="Talenta List"
  >
    <div class="dt_adv_search ml-1 mr-1">
      <div class="row">
        <div class="col-12">
          <div class="form-row justify-content-between mb-1">
            <div class="col-lg-4 mb-1 mb-lg-0">
              <select
                v-model="filter.sort_by_register"
                class="form-control form-control-sm"
              >
                <option value="">
                  -- Sory By Register --
                </option>
                <option value="asc">
                  Oldest
                </option>
                <option value="desc">
                  Newest
                </option>
              </select>
            </div>
            <div class="col-lg-4 mb-1 mb-lg-0">
              <select
                v-model="filter.status"
                class="form-control form-control-sm"
              >
                <option value="">
                  -- Sory By Status --
                </option>
                <option
                  v-for="(item, index) in sortByStatus"
                  :key="`sortByStatus${index}`"
                  :value="item"
                >
                  <span class="text-capitalize">{{ item }}</span>
                </option>
              </select>
            </div>
            <div class="col-lg-4 mb-1 mb-lg-0">
              <input
                v-model="filter.keyword"
                type="text"
                placeholder="Search by name or email"
                class="form-control form-control-sm float-right"
                style="width: 200px;"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive-sm">
      <table class="table b-table table-fixed">
        <thead>
          <tr>
            <th>Photo</th>
            <th>User</th>
            <th>Badan Hukum</th>
            <th>Status Cuti</th>
            <th>Status</th>
            <th>Kota</th>
            <th>Registration Time</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading">
            <td
              colspan="6"
              class="text-center"
            >
              <b-spinner
                class="mb-1"
                variant="primary"
              /><br>
              Loading...
            </td>
          </tr>
          <tr
            v-for="item in result.data"
            v-else
            :key="item.uuid"
          >
            <td>
              <b-img
                v-if="item.image != null"
                :src="item.image"
                style="max-width:100px;"
                thumbnail
                fluid
              />
              <span v-else>-</span>
            </td>
            <td>{{ item.name }}</td>
            <td class="text-center">
              <span :class="item.is_corporate == true ? 'text-success' : 'text-danger'">
                <feather-icon
                  :icon="item.is_corporate == true ? 'CheckCircleIcon' : 'XCircleIcon'"
                  class="mr-75"
                />
              </span>
            </td>
            <td class="text-center">
              <span :class="item.is_leave == true ? 'text-success' : 'text-danger'">
                <feather-icon
                  :icon="item.is_leave == true ? 'CheckCircleIcon' : 'XCircleIcon'"
                  class="mr-75"
                />
              </span>
            </td>
            <td>
              <span
                class="badge text-capitalize badge-pill"
                :class="item.status == 'active' ? 'badge-light-success' : 'badge-light-secondary'"
              >{{ item.status }}</span>
            </td>
            <td>
              {{ item.city ? item.city.name : '-' }}
            </td>
            <td>{{ item.register_at == null ? '-' : item.register_at }}</td>
            <td>
              <action-talenta
                :load-data="getData"
                :uuid="item.uuid"
                :status="item.status"
                :is-detail="true"
              />
            </td>
          </tr>
          <tr v-if="result.total == 0 && !isLoading">
            <td
              colspan="6"
              class="text-center"
            >
              Data is empty.
            </td>
          </tr>
        </tbody>
      </table>

      <div
        v-if="result.total > 0"
        class="m-1"
      >
        <div class="row">
          <div class="col mb-1">
            <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
          </div>
          <div class="col">
            <pagination
              :data="result"
              :limit="4"
              align="right"
              @pagination-change-page="getData"
            />
          </div>
        </div>
      </div>
    </div>
  </b-card-code>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BSpinner, BImg,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import Vue from 'vue'
import VuejsDialog from 'vuejs-dialog'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'

import DateRangePicker from '@/components/DatePicker.vue'

// Moment
import moment from 'moment'
import ActionTalenta from '@/components/Talenta/ActionTalenta.vue'

Vue.use(VuejsDialog)

export default {
  title() {
    return `Talenta List`
  },
  components: {
    BCardCode,
    BSpinner,
    BImg,
    ActionTalenta,
  },
  watch: {
    register_range(value) {
      this.filter.register_range = value[0] != null && value[1] != null ? this.formatDateRange(value[0], value[1]) : ''
    },
    filter: {
      handler: _.debounce(function () {
        this.getData()
      }, 300),
      deep: true,
    },
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      result: {},
      sortByStatus: ['active', 'pending', 'banned', 'rejected'],
      filter: {
        keyword: '',
        sort_by_register: '',
        status: '',
      },
      register_range: {
        startDate: null,
        endDate: null,
      },
      isLoading: false,
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData(page = 1) {
      this.isLoading = true
      this.currentPage = page
      const queryParams = this.filter
      queryParams.page = page

      this.$http.get('/admin/talents', {
        params: queryParams,
      })
        .then(response => {
          this.result = response.data.data
          this.isLoading = false
        })
    },
    formatDateRange(startDate, endDate) {
      return `${moment(startDate).format('Y-MM-DD')}:${moment(endDate).format('Y-MM-DD')}`
    },
  },
}
</script>
